import * as React from "react";
import {
  container,
  feedback,
  styledContainer,
  dates,
  subtitle,
} from "./GenerateGroupLink.module.scss";

export const Container: React.FC<any> = (props) => {
  return <div className={container}>{props.children}</div>;
};
export const Feedback: React.FC<any> = (props) => {
  return <div className={feedback}>{props.children}</div>;
};
export const StyledContainer: React.FC<any> = (props) => {
  return <div className={styledContainer}>{props.children}</div>;
};

export const Dates: React.FC<any> = (props) => {
  return <div className={dates}>{props.children}</div>;
};
export const Subtitle: React.FC<any> = (props) => {
  return <div className={subtitle}>{props.children}</div>;
};
