import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import addDays from "date-fns/addDays";
import { filter } from "lodash";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import IHotel from "../@types/IHotel";
import HotelAutocomplete from "../components/common/HotelAutoComplete/HotelAutoComplete";
import StayDates from "../components/common/Search/StayDates/StayDates";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import { useHotels } from "../hooks/useHotels";
import {
  Dates,
  Feedback,
  StyledContainer,
  Subtitle,
} from "../pageHelpers/GenerateGroupLink/GenerateGroupLinkHelpers";
import { formatDate, searchDateFormat } from "../services/dates";
import { validateMaxLengthMessage } from "../utils/validations";
const REQUIRED = "This field is required.";
const SELECT_PROMO = "Select a Group/Corporate code type.";

const GenerateGroupLink: React.FC = () => {
  const allHotels = useHotels();
  const { register, handleSubmit, errors, getValues, control, formState } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onBlur",
    });
  const [dates, setDates] = useState({
    checkIn: new Date(),
    checkOut: addDays(new Date(), 1),
  });
  const [radioRateCodes, setRadioRateCodes] = useState({
    isGroupCode: false,
    isPromoCode: false,
    isRateplanFilterCode: false,
  });
  const [bookingLink, setBookingLink] = useState("");
  const [hotelCode, setHotelCode] = useState("");
  const [alert, setAlert] = useState(false);
  const [isPromoSelected, setIsPromoSelected] = useState(true);
  const [_brandCodesStr, setBrandCodesStr] = useState("");
  const [rateCodeLabel, setRateCodeLabel] = useState("Code");

  const values = getValues();

  useEffect(() => {
    if (
      radioRateCodes.isGroupCode ||
      radioRateCodes.isPromoCode ||
      radioRateCodes.isRateplanFilterCode
    ) {
      setIsPromoSelected(true);
      let codeLabel = "Code";
      if (radioRateCodes.isGroupCode) {
        codeLabel = "Group Code";
      } else if (radioRateCodes.isPromoCode) {
        codeLabel = "Corporate/Promo Code";
      } else if (radioRateCodes.isRateplanFilterCode) {
        codeLabel = "Filter Code";
      }
      setRateCodeLabel(codeLabel);
    }
    radioRateCodes.isGroupCode && setBrandCodesStr("");
    setBookingLink("");
  }, [radioRateCodes]);

  const getHotelPath = (hotelCode: string) => {
    const selectedHotel = filter(allHotels, (h: IHotel) => {
      return hotelCode == h.hotel_code;
    });
    return selectedHotel && selectedHotel[0] && selectedHotel[0].path?.alias;
  };

  const onSubmit = (formData: any, event: any) => {
    event.preventDefault();
    setAlert(false);
    if (
      !radioRateCodes.isGroupCode &&
      !radioRateCodes.isPromoCode &&
      !radioRateCodes.isRateplanFilterCode
    ) {
      setIsPromoSelected(false);
      return false;
    }
    const { ratecode } = formData;
    const hotelPath = hotelCode ? getHotelPath(hotelCode) : null;

    let code = {};
    const queryStringCode = ratecode.trim();
    if (radioRateCodes.isGroupCode) {
      code = { groupCode: queryStringCode };
    }
    if (radioRateCodes.isPromoCode) {
      code = { promoCode: queryStringCode };
    } else if (radioRateCodes.isRateplanFilterCode) {
      code = { ratePlanFilterCode: queryStringCode };
    }
    const urlParams = {
      isGroupCode: radioRateCodes.isGroupCode,
      ...code,
      ...(!radioRateCodes.isRateplanFilterCode &&
        hotelCode &&
        !hotelPath && { hotelcode: hotelCode }),
      checkin: formatDate(dates.checkIn, searchDateFormat),
      checkout: formatDate(dates.checkOut, searchDateFormat),
    };
    const searchParams = new URLSearchParams(urlParams);
    if (
      !radioRateCodes.isGroupCode &&
      !radioRateCodes.isPromoCode &&
      !radioRateCodes.isRateplanFilterCode
    ) {
      return setIsPromoSelected(false);
    }

    const origin = window.location.origin;
    const path = hotelCode && hotelPath ? hotelPath : `/corp-group-booking`;
    const queryString = searchParams.toString();
    setBookingLink(`${origin}${path}?${queryString}`);
  };

  const onCopyClick = () => {
    navigator.clipboard.writeText(bookingLink);
    setAlert(true);
    /* Below will auto hide the alert after 5 secs */
    setTimeout(() => setAlert(false), 5000);
  };

  const handleHotelSelection = (data: any) => {
    setHotelCode(data.hotel_code);
  };

  const onAutoCompleteChange = (data: string) => {
    if (!data) {
      setHotelCode("");
    }
  };

  // React.useEffect(() => {
  //   values.brandcodes !== undefined && setBrandCodesStr(values.brandcodes.join(","));
  // }, [values]);

  return (
    <Layout>
      <SEO
        title={"Corporate | Group Rate Link Generation"}
        description={"Page to generate corporate or group rate link"}
        noIndex={true}
        noFollow={true}
      />
      <Container className="pt-5 pb-5" fluid="sm">
        <StyledContainer>
          <Alert
            show={alert}
            onClose={() => setAlert(false)}
            variant="success"
            className="text-center mx-auto"
            dismissible
          >
            Link copied to clipboard!
          </Alert>
          <Row>
            <Col>
              <Subtitle>Generate Corporate/Group Rate Link</Subtitle>
              <Form
                noValidate
                validated={false}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Form.Group controlId={`groupOrPromoCode`} as={Col} lg={12}>
                    <Form.Check
                      inline
                      required
                      id={`inline-1`}
                      name={"groupOrPromoCode"}
                      type={"radio"}
                      label="Group Code"
                      checked={radioRateCodes.isGroupCode}
                      onChange={() =>
                        setRadioRateCodes({
                          isGroupCode: !radioRateCodes.isGroupCode,
                          isPromoCode: !!radioRateCodes.isGroupCode,
                          isRateplanFilterCode: !!radioRateCodes.isGroupCode,
                        })
                      }
                    />
                    <Form.Check
                      inline
                      required
                      id={`inline-2`}
                      type={"radio"}
                      name={"groupOrPromoCode"}
                      label="Corporate/Promo Code"
                      checked={radioRateCodes.isPromoCode}
                      onChange={() =>
                        setRadioRateCodes({
                          isGroupCode: !!radioRateCodes.isPromoCode,
                          isPromoCode: !radioRateCodes.isPromoCode,
                          isRateplanFilterCode: !!radioRateCodes.isPromoCode,
                        })
                      }
                    />
                    <Form.Check
                      inline
                      required
                      id={`inline-2`}
                      type={"radio"}
                      name={"groupOrPromoCode"}
                      label="Rateplan Filter Code"
                      checked={radioRateCodes.isRateplanFilterCode}
                      onChange={() =>
                        setRadioRateCodes({
                          isGroupCode: !!radioRateCodes.isRateplanFilterCode,
                          isPromoCode: !!radioRateCodes.isRateplanFilterCode,
                          isRateplanFilterCode:
                            !radioRateCodes.isRateplanFilterCode,
                        })
                      }
                    />
                    <Feedback role="Alert" aria-live="Assertive">
                      {!isPromoSelected && SELECT_PROMO}
                    </Feedback>
                  </Form.Group>
                </Row>

                <Row className="mt-3">
                  <Form.Group as={Col} lg={6} controlId="ratecode">
                    <Form.Label>{rateCodeLabel}*</Form.Label>
                    <Controller
                      as={
                        <Form.Control
                          isInvalid={errors.ratecode}
                          aria-required={true}
                        />
                      }
                      type="text"
                      name="ratecode"
                      control={control}
                      rules={{
                        required: REQUIRED,
                        maxLength: {
                          value: 20,
                          message: validateMaxLengthMessage(20),
                        },
                      }}
                      defaultValue={values.ratecode || ""}
                    />
                    <Form.Control.Feedback>
                      {errors.ratecode && errors.ratecode.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} lg={6} controlId="dates">
                    <Form.Label>Check-In | Check-Out</Form.Label>
                    <Dates>
                      <StayDates
                        from={dates.checkIn}
                        to={dates.checkOut}
                        checkinDayChange={(checkIn) =>
                          setDates({ checkIn, checkOut: undefined })
                        }
                        checkoutDayChange={(checkOut) =>
                          setDates({ ...dates, checkOut })
                        }
                        shouldHide
                      />
                    </Dates>
                  </Form.Group>

                  <Form.Group as={Col} lg={6} controlId="hotel">
                    <Form.Label>Hotel Name *</Form.Label>
                    <HotelAutocomplete
                      onHotelSelection={handleHotelSelection}
                      id="hotel"
                      inputRef={register({
                        required: REQUIRED,
                      })}
                      name="hotel"
                      errors={errors.hotel}
                      suggestions={values.hotel}
                      onAutoCompleteChange={onAutoCompleteChange}
                    />
                  </Form.Group>
                </Row>
                {bookingLink.length && isPromoSelected ? (
                  <Row className="mt-1">
                    <Form.Group as={Col}>
                      <InputGroup>
                        <Form.Control
                          readOnly
                          type="textarea"
                          value={bookingLink}
                          aria-label="Booking Link"
                        />
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <span>
                              <FontAwesomeIcon
                                icon={faCopy}
                                onClick={onCopyClick}
                              />
                            </span>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                ) : null}
                <Button
                  type="submit"
                  disabled={!formState.isValid || !hotelCode}
                  className="text-uppercase w-100 mt-4 btn-link"
                >
                  {`${
                    bookingLink.length && isPromoSelected
                      ? "Update"
                      : "Generate"
                  } Link`}
                </Button>
              </Form>
            </Col>
          </Row>
        </StyledContainer>
      </Container>
    </Layout>
  );
};

export default GenerateGroupLink;
