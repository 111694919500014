// extracted by mini-css-extract-plugin
export var alt = "GenerateGroupLink-module--alt--1b38e";
export var container = "GenerateGroupLink-module--container--026f5";
export var dates = "GenerateGroupLink-module--dates--d539b";
export var desktopOnly = "GenerateGroupLink-module--desktopOnly--ee444";
export var feedback = "GenerateGroupLink-module--feedback--15248";
export var main = "GenerateGroupLink-module--main--515f2";
export var mobileOnly = "GenerateGroupLink-module--mobileOnly--896f6";
export var styledContainer = "GenerateGroupLink-module--styledContainer--d14f3";
export var subtitle = "GenerateGroupLink-module--subtitle--46ab8";
export var textLeft = "GenerateGroupLink-module--textLeft--b27a8";
export var visuallyHidden = "GenerateGroupLink-module--visuallyHidden--4a946";